import React, { useEffect, useState } from 'react';
import FormInputAttachContent from './FormAttachContent';
import FormCheckbox from './FormCheckbox';
import FormRadio from './FormRadio';
import FormSelectInput from './FormSelect';
import FormTextInput from './FormTextInput';
import FormTextAreaInput from './FormTextAreaInput';
import FormDateInput from './FormDateInput';
import { Form, Button, Row } from 'react-bootstrap';
import fieldPropMapping from './fieldPropsMapping.js';
import '../Request-Detail/RequestDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import FormCTA from './FormCTA';
import { FormGroupBg } from './styles';
import DisplayErrorMessage from '../DisplayErrorMessage';
import { setPageInstructions, setEEEnabled } from '../../../../reducers/requestDetailFormReducer';

const RequestDetailsForm = ({
  caseID,
  caseIDOriginal,
  isReview,
  flatListData,
  attachmentCategory,
  nextAssignmentId,
  currentType,
  fromview,
  hazardousChanged,
  checkCCCAvail,
  checkWBSAvail,
  cccMessageFromPega,
  wbsMessageFromPega,
}) => {
  const formData = useSelector((state) => state.requestForm.formFields);
  const hasEEEnabled = useSelector((state) => state.profile.myServices.hasEEEnabled);

  const [validated, setValidated] = useState(false);
  const [fieldsProcessed, setFieldsProcessed] = useState(false);
  const dispatch = useDispatch();

  // Dispatch EEEnabled
  useEffect(() => {
    dispatch(setEEEnabled(hasEEEnabled));
  }, []);

  const componentMap = (field) => {
    // eslint-disable-next-line default-case
    switch (field?.type) {
      case 'pxDropdown':
        return <FormSelectInput {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
      case 'pxTextInput':
        return (
          <FormTextInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formData}
            setCCMessage={cccMessageFromPega}
            setWBSMessage={wbsMessageFromPega}
            isCCCValid={onCheckCCC}
            isWBSValid={onCheckWBS}
          />
        );
      case 'pxInteger':
        return <FormTextInput {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
      case 'pxTextArea':
        return <FormTextAreaInput {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
      case 'pxCheckbox':
        return <FormCheckbox {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
      case 'pxRadioButtons':
        return (
          <FormRadio
            formData={formData}
            caseID={caseID}
            nextAssignmentId={nextAssignmentId}
            {...field}
            fieldPropMapping={fieldPropMapping}
            isHazardousSelected={onHazardousSelected}
          />
        );
      case 'pxAttachContent':
        return (
          <FormInputAttachContent
            {...field}
            isReview={isReview}
            attachmentCategory={attachmentCategory}
            caseIDOriginal={caseIDOriginal}
            fieldPropMapping={fieldPropMapping}
            currentType={currentType}
            formData={formData}
            fromview
          />
        );
      case 'pxDateTime':
        return <FormDateInput {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
      case 'pxButton':
        return <FormCTA {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
      case 'caption':
        return <Form.Label>{field.captionFor}</Form.Label>;
      case 'default':
        return <FormTextInput {...field} fieldPropMapping={fieldPropMapping} formData={formData} />;
    }

    return null;
  };
  const onHazardousSelected = (val) => {
    hazardousChanged(val);
  };

  const onCheckCCC = (value) => {
    checkCCCAvail(value);
  };
  const onCheckWBS = (value) => {
    checkWBSAvail(value);
  };
  const formSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated((prev) => !prev);
  };

  useEffect(() => {
    setValidated(false);
    setFieldsProcessed(true);
  }, [formData]);

  useEffect(() => {
    dispatch(setPageInstructions([]));
  }, []);

  return (
    <Form className="container" onSubmit={(e) => formSubmit(e)} noValidate validated={validated}>
      <Row>
        <DisplayErrorMessage formFields={formData} />

        {Object.values(formData).map((field, fieldIndex) => {
          return Array.isArray(field) ? (
            <React.Fragment key={`field-${fieldIndex}`}>
              {field.map((fieldGroup, groupIndex) => {
                return (
                  <React.Fragment key={`fieldGroup-${groupIndex}`}>
                    {fieldGroup.length > 0 && (
                      <FormGroupBg key={`formGroup-${groupIndex}`}>
                        {fieldGroup.map((fieldItem, itemIndex) => {
                          return (
                            <React.Fragment key={`fieldItem-${itemIndex}`}>{componentMap(fieldItem)}</React.Fragment>
                          );
                        })}
                      </FormGroupBg>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ) : (
            <React.Fragment key={`field-${fieldIndex}`}>{componentMap(field)}</React.Fragment>
          );
        })}
      </Row>
    </Form>
  );
};

export default RequestDetailsForm;
