import { Box, Grid, Heading } from '@chakra-ui/react';
import React from 'react';
import { Card } from '../../../../components/ServiceUI/Core/Card/Card';
import { appConstants } from '../../../../helper/client/constant';
import {
  getBaseURL,
  getDEAP_URL,
  getFormsURL,
  getIAU_URL,
  getPostingConnectURL,
  getTravelURL,
} from '../../../../helper/getOtherAppURL';

export interface CardProps {
  title?: string;
  description?: string;
  dpeDescription?: string;
  ctaText?: string;
  ctaUrl?: string;
  image?: string;
  altText?: string;
  isButton?: boolean;
  isExternal?: boolean;
  pmKeysOnly?: boolean;
  showOnlyIfDefenceIdentityIsLinked?: boolean;
  isPostingConnect?: boolean;
  isTravel?: boolean;
  isDEAP?: boolean;
  isIAU?: boolean;
  isEEnabled?: boolean;
  info?: boolean;
  preamble?: string;
}

export interface CardPanelsProps {
  heading: string;
  panels: CardProps[];
  variation?: string;
  myServices?: MyServices;
  hasPMKeys?: boolean;
  hasLinkedDefenceEmail?: boolean;
}
export interface MyServices {
  hasPostingConnect?: boolean;
  hasTravel?: boolean;
  hasDEAP?: boolean;
  hasIAU?: boolean;
  hasEETileEnabled?: boolean;
}

const CtaPanels = ({ heading, panels, variation, myServices, hasPMKeys, hasLinkedDefenceEmail }: CardPanelsProps) => {
  // Process panels data and remove any panels that should not be displayed based on the user's accessGroup
  const hasPostingConnectAccess = myServices?.hasPostingConnect || false;
  const hasTravelAccess = myServices?.hasTravel || false;
  const hasDEAP = myServices?.hasDEAP || false;
  const hasIAU = myServices?.hasIAU || false;
  const hasEEEnabled = myServices?.hasEETileEnabled || false;

  const shouldShow = (tile: CardProps) => {
    // EE Portal: If showOnlyIfDefenceIdentityIsLinked is true and the user does not have PMKeys or a linked defence email, return null
    if (tile.showOnlyIfDefenceIdentityIsLinked && !hasPMKeys && !hasLinkedDefenceEmail) {
      return false;
    }
    if (!tile.isIAU && !tile.isDEAP && !tile.isPostingConnect && !tile.isTravel && !tile.info && !tile.isEEnabled) {
      return true;
    }
    if (tile.isPostingConnect === hasPostingConnectAccess) {
      return true;
    }
    if (tile.isIAU === hasIAU) {
      return true;
    }
    if (tile.isEEnabled === hasEEEnabled) {
      return true;
    }
    if (tile.isDEAP === hasDEAP) {
      return true;
    }
    if (tile.isTravel === hasTravelAccess) {
      return true;
    }
    if (tile.info) {
      return !hasPostingConnectAccess;
    }
    return false;
  };

  return (
    <Box data-testid="CardPanel">
      <Heading as="h2" fontWeight="medium" fontSize="regular">
        {heading}
      </Heading>
      <Grid templateColumns="repeat(auto-fit, minmax(345px, 1fr))" gap={{ lg: '25px', base: '25px' }}>
        {panels.map((item) => {
          return (
            shouldShow(item) && (
              <Card
                header={item.title}
                content={item.description}
                image={item.image ? require(`../../../../images/${item.image}`) : undefined}
                alt={item.altText}
                linkText={item.ctaText}
                isButton
                buttonVariant={`${variation === 'primary' ? 'primary' : 'secondary'}`}
                linkURL={
                  item.ctaText === appConstants.VISIT_MY_FORMS
                    ? getFormsURL()
                    : item.ctaText === appConstants.VISIT_MY_BASE
                    ? getBaseURL()
                    : item.isPostingConnect === true
                    ? getPostingConnectURL()
                    : item.isIAU === true
                    ? getIAU_URL()
                    : item.isDEAP === true
                    ? getDEAP_URL()
                    : item.isTravel === true
                    ? getTravelURL()
                    : item.ctaUrl
                }
                isExternal={item.isExternal}
                key={item.title}
                preamble={item.preamble}
              />
            )
          );
        })}
      </Grid>
    </Box>
  );
};

export default CtaPanels;
