import { Box, Flex, Heading, Grid, Text, useDisclosure, GridItem, Spacer } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SubContainer } from '../../../Core/Layout';
import { MyDataManagementItem } from './MyDataManagementItem';
import { Consent } from '../types';
import { StartPopup } from './StartPopups/StartPopup';
import { DSAS } from './StartPopups/DSAS';
import { useSelector } from 'react-redux';
import { Profile } from '../../../../../types';

export interface MyDataManagementDashboardProps {}

export interface PanelProps {
  consents: Consent[];
}

export const MyDataManagementDashboard: React.FC<MyDataManagementDashboardProps> = () => {
  const DSASDetails = useSelector((state: { profile: Profile }) =>
    state.profile?.consents?.find((consent) => consent.name === 'DSAS'),
  );
  const { isOpen: isStartFormOpen, onToggle } = useDisclosure();

  const [modalContent, setModalContent] = useState<{ Content: React.FC; link: string }>();

  const handleOpen = (Content, link) => {
    onToggle();
    setModalContent({ Content, link });
  };

  const Toolbar = () => {
    return (
      <Grid
        fontSize="xxs"
        px="1.5rem"
        alignItems="flex-end"
        width="100%"
        templateColumns="repeat(6,1fr)"
        gap={3}
        fontWeight="light"
        display={{ lg: 'grid', base: 'none' }}
      >
        <Text as={GridItem} colSpan={2}>
          Status
        </Text>
        <Text as={GridItem} colSpan={2}>
          Consent name
        </Text>
        <Text as={GridItem} colSpan={1}>
          Expiry date
        </Text>
        <Spacer as={GridItem} colSpan={1} />
      </Grid>
    );
  };

  return (
    <Box bg="white" id="consentmanagement">
      <StartPopup isOpen={isStartFormOpen} close={onToggle} content={modalContent} />
      <SubContainer verticalMargin="1rem">
        <Heading as="h3" fontWeight="medium" fontSize="sm">
          My Data Management
        </Heading>
        <Text fontWeight="light" mb="1rem" color="orange.standard">
          This form is intended only for use by Defence personnel participating in the DVA Data Sharing and Analytics
          Solution (DSAS) pilot.
          <br />
          The form is a mock-up of the DSAS consent request and will be used only to evaluate engagement with the DSAS
          consent campaign pilot. Your response to the form will not be used as evidence of consent for your personal
          information to be collected, used or disclosed for DSAS. You may update your consent response for the DSAS
          Pilot at any time by recompleting this form.
        </Text>
        <Text fontWeight="light" mb="1rem">
          Use this section to view and manage your consent for data sharing.
        </Text>
        <Toolbar />
        <Flex flexWrap="wrap" gap={3}>
          <MyDataManagementItem
            title="Defence and DVA Data Sharing and Analytics Solution (DSAS) Consent Form"
            expiryDate={DSASDetails?.expiryDate ? new Date(DSASDetails.expiryDate) : undefined}
            status={
              DSASDetails
                ? DSASDetails?.status === 'true'
                  ? 'CONSENT-PROVIDED'
                  : 'CONSENT-NOT-PROVIDED'
                : 'CONSENT-NOT-PROVIDED'
            }
            start={() => {
              handleOpen(<DSAS />, '/new-consent/DSAS');
            }}
            buttonText={DSASDetails ? 'Manage' : 'Start'}
          />
        </Flex>
      </SubContainer>
    </Box>
  );
};
