import React, { useEffect, useState } from 'react';
import { createCase } from './requests';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Field } from '../types';
import { findAllItemsMultiKey } from '../../../../../helper/findAllItems';
import { Fields } from './Fields';
import { SubContainer } from '../../../Core/Layout';
import { ActionButtons } from './ActionButtons';
import { prefill } from './PrefillService';
import { submit } from './Submit';
import { Accordion, Box, Text } from '@chakra-ui/react';
import { Spinner } from '../../../Core/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../../../../../types';
import { GenericAccordionItem } from '../../../Core/Accordion/GenericAccordionItem';

export interface DSASFormProps {
  profileData: Profile;
}

interface Inputs {}

export const DSASForm: React.FC<DSASFormProps> = ({ profileData }) => {
  const isCivilian = profileData.service?.short === 'Civilian';
  //React Hook Form methods
  const methods = useForm({ mode: 'onTouched', shouldUnregister: true });

  const isPrivacyChecked: Boolean = methods.watch('ConsentPrivacyNote');

  //State for the controls
  const [controls, setControls] = useState<Field[]>();
  const [caseID, setCaseID] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setSubmitting(true);
    submit(data, caseID).then(() => navigate('/new-consent/DSAS/complete'));
  };
  //Creating case and retrieving controls from Pega
  useEffect(() => {
    createCase()
      .then((res: any) => {
        setControls(findAllItemsMultiKey(res, ['control', 'paragraph']));
        setLoading(false);
        setCaseID(res.caseID);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    prefill(methods.setValue, profileData);
  }, [profileData]);

  return (
    <SubContainer verticalMargin="1rem">
      {isCivilian ? (
        <>This form is only available to ADF members</>
      ) : (
        <>
          <>{loading && <Spinner />}</>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              {/* Create two sections, the split occuring at the privacy notice checkbox */}
              <Fields
                fields={
                  controls?.slice(0, controls.findIndex((control) => control.fieldID === 'ConsentPrivacyNote') + 1) ??
                  []
                }
              />
              {/* Only showing final section and buttons once the checkbox is checked */}
              <Box display={isPrivacyChecked ? 'block' : 'none'}>
                <Fields
                  fields={
                    controls?.slice(
                      controls.findIndex((control) => control.fieldID === 'ConsentPrivacyNote') + 1,
                      controls.findIndex((control) => control.fieldID === 'ConsentProvided') + 1,
                    ) ?? []
                  }
                />
                <Accordion allowToggle>
                  <GenericAccordionItem title="List of relevant definitions for this form">
                    {controls
                      ?.slice(
                        controls.findIndex((control) => control.fieldID === 'ConsentProvided') + 1,
                        controls.length,
                      )
                      .map((item) => <Text dangerouslySetInnerHTML={{ __html: item.paragraph.value }} />) ?? <></>}
                  </GenericAccordionItem>
                </Accordion>
                <ActionButtons isSubmitting={submitting} />
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </SubContainer>
  );
};
