import React, { useEffect, useState } from 'react';
import SubHeader from '../../components/ServiceUI/Core/SubHeader/SubHeader';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { appConstants } from '../../helper/client/constant';
import content from './Content/Profile.json';
import { BaseInductions } from '../../components/ServiceUI/LandingPage/BaseInductions/BaseInductions';
import { MyDataManagementDashboard } from '../../components/ServiceUI/LandingPage/MyDataManagement/MyDataManagementDashboard/MyDataManagementDashboard';
import { SubCTA } from '../../components/ServiceUI/LandingPage/SubCTA/SubCTA';
import { Box } from '@chakra-ui/react';
import { getTravelURL } from '../../helper/getOtherAppURL';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../helper/useAxios';
import { setProfile } from '../../reducers';
import { MyPreferences } from '../../components/ServiceUI/LandingPage/MyPreferences/MyPreferences';
import { Profile as ProfileType } from '../../types';
import { Profile } from '../../components/ServiceUI/LandingPage/Profile/Profile';
import { BaseRef } from '../../types';
import { Option } from '../../components/ServiceUI/Core/FormElements/FormElementType';

export const ProfilePage = (props) => {
  const [baseRefData, setBaseRefData] = useState<Option[]>([]);
  const [workingLocationRefData, setWorkingLocationRefData] = useState<Option[]>([]);

  const dispatch = useDispatch();
  const isCivilianOrContractor = useSelector(
    (state: { profile: ProfileType }) =>
      state.profile.service.short === 'Civilian' || state.profile.service.short === '',
  );
  const isInternal = useSelector((state: { isInternal }) => state.isInternal);

  const updateProfile = () => {
    request(appConstants.API_USER_PROFILE_DETAILS, 'get').then((res) => dispatch(setProfile(res.data)));
  };

  const getBaseRefData = () => {
    request(appConstants.API_BASE_LOCATION, 'get').then((res) =>
      setBaseRefData(
        res.data.locDetails.map((item: BaseRef) => {
          return { key: item.aemLocRefValue, value: item.name };
        }),
      ),
    );
    request(appConstants.API_LOCATION_DETAILS, 'get').then((res) =>
      setWorkingLocationRefData(
        res.data.locDetails.map((item: BaseRef) => {
          return { key: item.fId, value: item.name };
        }),
      ),
    );
  };

  useEffect(() => {
    document.title = 'Profile';
    updateProfile();
    getBaseRefData();
  }, []);

  const profileData = useSelector((state: { profile: ProfileType }) => state.profile);

  return (
    <>
      <Header {...content.header} />
      <SubHeader {...content.subheader} />
      <Profile
        {...content.profile}
        profile={profileData}
        baseRefData={baseRefData}
        workingLocationRefData={workingLocationRefData}
      />
      {profileData.myServices.hasTravel && (
        <Box mb="3rem">
          <SubCTA {...content.travelProfileCTA} buttonURL={getTravelURL()} />
        </Box>
      )}
      <MyPreferences {...content.myPreferencesContent} />
      {/* MDM Dashboard is only displayed:
        1. when the feature is enabled.
        2. when the user is not a civilian or contractor.
        3. the user is logged in internally */}
      {profileData.myServices.hasMDMEnabled && !isCivilianOrContractor && isInternal && <MyDataManagementDashboard />}
      <BaseInductions basesInducted={props.profileData.basesInducted} {...content.baseInductionContent} />
    </>
  );
};
